import { CalculationExpUtil } from "../../../../../../../../utils/descriptor/calculationExp";
import DescriptorType from "../../../../../../../../utils/descriptor/descriptorType";
import get from "../../../../../../../../utils/idFactory";

/**
 * TODO: This file should be extracted out of the pages folder and put into a separate folder/file
 * for more general utils since it is used in multiple places outside of the admin page
 */
class Descriptor {
    /**
     * 
     * @param {string} id  Optional 
     * @param {string} label Optional
     * @param {string[]| KeyValue[]} predefinedValues  Optional
     * @param {boolean} editable Specifies if the descriptor is allowed to be modified by the client. Optional
     * @param {css{}} style Optional 
     * @param {boolean} periodToPeriodCalc Optional
     * @param {DescriptorType} type Optional 
     * @param {boolean} dynamicField Optional 
     * @param {int} inputScale One of the specified in {ScaleUtil} Optional
     * @param {int} viewScale One of the specified in {ScaleUtil} Optional
     * @param {{categoyId: string, inverseSign:boolean}} category Optional
     */
    constructor(id, label = "", predefinedValues, editable = true, style = {},
        periodToPeriodCalc = true, type = DescriptorType.NUMBER,
        dynamicField = true, inputScale = 1000, viewScale = 1000, dataCalcType = "NA", category = null) {


        this.id = id === undefined ? get("custom") : id
        this.label = label
        this.editable = editable
        this.style = style
        this.periodToPeriodCalc = periodToPeriodCalc
        this.type = type
        this.inputScale = inputScale
        this.viewScale = viewScale
        this.dataCalcType = dataCalcType
        this.scalable = true
        this.dynamicField = dynamicField
        this.predefinedValues = predefinedValues
        this.calculation = CalculationExpUtil.NO_OP_CALC
        this.secondaryCalculation = CalculationExpUtil.NO_OP_CALC
        this.subFields = []
        this.importance = 0
        this.category = category
    }
}

class DescriptorCategoryProps {
    static CATEGORY_NAME = "categoryName"
    static CATEGORY_ID = "categoryId"
    static INVERSESIGN = "inverseSign"
}

class DataCalcType {
    static CUMULATIVE = "CUMULATIVE"
    static NON_CUMULATIVE = "NON_CUMULATIVE"
    static VERTICAL = "VERTICAL"
    static NA = "NA"

    /**
     * @returns not-null list of all data calc types 
     */
    static getAllTypes() {
        return [
            DataCalcType.CUMULATIVE, DataCalcType.NON_CUMULATIVE,
            DataCalcType.VERTICAL, DataCalcType.NA
        ]
    }

}

export { Descriptor, DataCalcType, DescriptorCategoryProps }




