class ReportField {

    static PERIOD = "period"
    static SRC = "src"
    /**
     * Filing type (Currently report version [Latest, Original])
     */
    static TYPE = "type"
    /**
     * Currently report format [Proforma, Standard, Condensed]
     */
    static REPORT_TYPE = "reportType"
    static YEAR = "year"
    static METADATA = "metadata"
    static DYNAMIC_FIELDS = "dynamicFields"
    static MANAGED = "managed"
    static OWNER_ID = "ownerId"
    static FULL_CHECKS_COUNT = "fullChecksCount"
    static VISUAL_CHECKS_COUNT = "visualChecksCount"
    static CREATED_ON = "createdOn"
    static UPDATED_ON = "updatedOn"

    //NOT PERSISTED Fields
    static CREATED_ON_DATE_ONLY = "createdOnDateOnly"
    static UPDATED_ON_DATE_ONLY = "updatedOnDateOnly"
    static OWNER_NAME = "ownerName"

}

class MetadataField {
    static REPORTED_AS_LABEL = "reportedAsLabel"
    static MANUALLY_CALCED = "manuallyCalculated"
    static SRC = "src"
    static NOTES = "notes"
    static CALCS_DISABLED = "calcsDisabled"
    static CORRECTION = "correction"
    static INPUT_SCALE = "inputScale"
    static EXTENDED_SEARCH = "extendedSearch"

    //NOT PERSISTED
    /**
     * User friendly explanation of the calculation e.g. H1 - Q1 
     */
    static P2P_CALC = "p2pCalc"
    /**
     * Result of a calculation
     */
    static P2P_CALC_RES = "p2pCalcRes"
    /**
     * Description of the method used for inconsistancy detection
     */
    static DATA_DIFF_DETECTION = "dataDiffDetection"
    /**
     * The data that was expected by the inconsistancy detection method 
     */
    static DATA_DIFF_EXPECTATION = "dataDiffExp"

    /**
     * The data is a measurement of how much the value of the descriptor for a given report 
     * has increased or decreased relative to the value of the same report for the previous year/period
     */
    static YEARLY_GROWTH_PERCENT = 'yearlyGrowthPercent'
    static PERIOD_GROWTH_PERCENT = "periodGrowthPercent"
}

/**
 * First level report metadata props
 */
class ReportMetadataField {
    //NOT PERSISTED
    static OBSOLETE = "obsolete"
    //NOT PERSISTED
    static DUPLICATE = "duplicate"
}


class SrcFieldValue {

    static MIXED = "Mixed"
    static REPORT = "Report"
    static PRESS_RELEASE = "Press Release"
    static PRESENTATION = "Presentation"
    static OTHER = "Other"
    static CALCULATED = "Calc."
    static EIGHT_K = "8K"
    static EIGHT_Q = "8Q"
    static TEN_K = "10K"
    static TEN_Q = "10Q"
    static USER_INFO = "User Info"

    static ALL_PERSISTED = [
        SrcFieldValue.REPORT, SrcFieldValue.PRESS_RELEASE, SrcFieldValue.PRESENTATION,
        SrcFieldValue.EIGHT_K, SrcFieldValue.EIGHT_Q, SrcFieldValue.TEN_K,
        SrcFieldValue.TEN_Q, SrcFieldValue.MIXED, SrcFieldValue.OTHER,
        SrcFieldValue.USER_INFO
    ]

    static ALL = [
        ...SrcFieldValue.ALL_PERSISTED, SrcFieldValue.CALCULATED
    ]
}

class ReportFillingType {
    static ORIGINAL_FILLING = "Original"
    static LATEST_FILLING = "Latest"
    static EXPECTATION = "Exp."

    /**
     * ORDERED
     */
    static PERSISTENT_TYPES = [
        ReportFillingType.ORIGINAL_FILLING,
        ReportFillingType.LATEST_FILLING,
        // ReportFillingType.EXPECTATION
    ]
}

class ReportType {
    static STANDARD = "Standard"
    static CONDENSED = "Condensed"
    static PROFORMA = "Proforma"

    /**
     * ORDERED
     */
    static ALL = [ReportType.STANDARD, ReportType.CONDENSED, ReportType.PROFORMA]

}

const DATA_SOURCES = {
    FIELD_VALUE: "FIELD_VALUE",
    P2P_CALC: 'P2P_CALC',
    DATA_DIFF: "DATA_DIFF"
}



export { ReportField, SrcFieldValue, MetadataField, ReportFillingType, ReportType, ReportMetadataField, DATA_SOURCES }