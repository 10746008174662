import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Dropdown, Form, Header, Icon, Label, Modal, Popup, Segment, Table, TextArea } from 'semantic-ui-react'
import Loader from '../../loader';
import AuthService from '../../../service/auth';
import TableUpdatesRepo from '../../../utils/repository/tableUpdatesRepo'
import { serverToLocalDate } from '../../../utils/dateTimeUtils';
import { reportStatusToLablel } from '../../../utils/labelsConverter';
import ReviewTab from '../review/review';
import KeyValue from '../../../structures/keyValePair';
import UserRepo from '../../../utils/repository/userRepo';
import CompanyRepo from '../../../utils/repository/companyRepo';
import { SelectField } from '../../form/fields';
import { ROLES } from '../../../constnats/user';
import { Link } from 'react-router-dom';
import { AdministrationTabs } from '../../../pages/admin/company/company';
import RouteC from '../../../constnats/routeConstants';
import ConfirmationModal from '../../confirmationModal';
import Alert from '../../../utils/alert';
import { ReviewType, UpdatePhase, UpdatePurpose } from '../../financialTables/configuration/constants';
import ReportsRepo from '../../../utils/repository/repoertsRepo';
import { ReportField } from '../../../constnats/reportConstants';
import { ReportsUtils } from '../../../utils/reportsUtils';
import TableUpdates from '../../../service/tableUpdates';
import NumInput from '../../form/numInput';
import Assert from '../../../utils/asserts';
import { PathFactory } from '../../../utils/repository/utils';

const deleteConfirmationMsg = "Are you sure that you want to delete the update? The operation will also delete all data associated with it."

const UPDATE_PURPOSES = [
    new KeyValue(UpdatePurpose.REGULAR_UPDATE, "Regular Update"),
    new KeyValue(UpdatePurpose.DEFERRED_REVIEWS_EXECUTION, "Execution of deferred reviews"),
    new KeyValue(UpdatePurpose.MISTAKES_DETECTION, "General mistakes detection")
]

function valdateDescription(description) {
    return description.length > 5 && description.length < 1000
}

function validateMinChecks(fullChecks, visualChecks) {
    return fullChecks + visualChecks > 0
}

const INVALID_DESCRIPTION_MSG = 'The descriptin must be at least 5 characters long and not more than 1000.'
const INVALID_MINCHECKS_MSG = 'The update should have at least one check (Visual, Full or Core Data)'


function hasReviews(allReviews, type, minReviews) {
    Assert.notNullOrUndefined(minReviews, "Unexpected value for minReviews")
    return allReviews.filter(r => r.type === type).length >= minReviews
}

function isReadyForNextPhase(update) {

    const reviews = update.reviews
    const phase = update.phase
    const qaReviewBlocker = phase === UpdatePhase.READY_FOR_QA_REVIEW ?
        hasReviews(reviews, ReviewType.VISUAL_CHECK, update.minVisualChecks) : true

    return phase !== UpdatePhase.APPLIED && qaReviewBlocker &&
        hasReviews(reviews, ReviewType.FULL_CHECK, update.minFullChecks) &&
        hasReviews(reviews, ReviewType.CORE_DATA_CHECK, update.minCDChecks) &&
        reviews.every(r => r.status === "APPROVED")
}

function UserSelect({ selected, disabled, multiple, label, onChange }) {
    const [users, setUsers] = useState([])

    useEffect(() => {
        UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled: false },
            users =>
                setUsers(
                    users.map(r => {
                        return {
                            key: r.id,
                            text: r.fullName,
                            value: r.id
                        }
                    }))
        )
    }, [])

    const options = users.filter(u => !disabled.includes(u.key))
    return (
        <Form.Field>
            <label>{label}</label>
            <Dropdown
                onChange={(a, b) => onChange(b.value)}
                value={selected}
                placeholder={label}
                {...{ multiple, options }}
                fluid selection search />
        </Form.Field>
    )
}

function CreateUpdateModal({ companyId, schemaId, summary, onSaved, onCancel }) {
    const [description, setDescription] = useState("")
    const [purpose, setPurpose] = useState(UPDATE_PURPOSES[0].key)
    const [targetReviewers, setTargetReviewers] = useState([])
    const [reportsForDeferredCheck, setReportsForDeferredCheck] = useState([])

    const [error, setError] = useState(false)
    const [loading, setLoading] = React.useState(true)

    const ownerId = AuthService.getUserId()

    function save() {
        if (valdateDescription(description)) {
            setLoading(true)
            const spec = {
                summary,
                description,
                purpose,
                targetReviewers,
                srcSchema: schemaId,
                reportsForDeferredCheck:
                    purpose === UpdatePurpose.DEFERRED_REVIEWS_EXECUTION ? reportsForDeferredCheck : []
            }

            TableUpdatesRepo.create(spec, () => {
                setLoading(false)
                onSaved()
            })
        } else {
            setError({ content: INVALID_DESCRIPTION_MSG })
        }
    }

    useEffect(() => {
        CompanyRepo.getContributors(companyId, contributors => {
            const targetReviewers = contributors
                .filter(u => ownerId !== u.id)
                .map(u => u.id)


            setTargetReviewers(targetReviewers)
            setLoading(false)
        })
    }, [companyId]);

    return loading ? <Loader /> :
        <Modal size="small" open={true}>
            <Modal.Header>Create Update</Modal.Header>
            <Modal.Content>
                <Form>
                    <SelectField value={purpose} number={false}
                        onChange={setPurpose}
                        label="Purpose" valuesMap={UPDATE_PURPOSES} />
                    Description
                    <Form.Field
                        error={error}
                        control={TextArea}
                        value={description} onChange={v => {
                            setError(false)
                            setDescription(v.target.value)
                        }} />
                    {purpose === UpdatePurpose.DEFERRED_REVIEWS_EXECUTION &&
                        <DeferredReviewReportsSelection
                            schemaId={schemaId}
                            selectedReportIds={reportsForDeferredCheck}
                            onChange={setReportsForDeferredCheck} />
                    }
                    <UserSelect
                        label="Target Reviewers"
                        disabled={[ownerId]}
                        multiple={true}
                        onChange={setTargetReviewers}
                        selected={targetReviewers} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button size='mini' positive onClick={save}>
                    Create
                </Button>
                <Button size='mini' negative onClick={onCancel}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
}


function DeferredReviewReportsSelection({ schemaId, selectedReportIds, onChange }) {
    const [reports, setReports] = useState([])

    useEffect(() => {
        ReportsRepo.listBySchema(schemaId, setReports)
    }, [schemaId]);

    return <>
        <b>Reports for deferred review:</b>
        <table className='reportsSelection' >
            <thead>
                <tr>
                    <th></th>
                    <th>Year</th>
                    <th>Period</th>
                    <th>Version</th>
                    <th>Format</th>
                    <th>Full Checks</th>
                    <th>Visual Checks</th>
                    <th>Created On</th>
                    <th>Updated On</th>
                </tr>
            </thead>
            <tbody>
                {ReportsUtils.sort(reports, true).map(report =>
                    <tr key={report.id}>
                        <td>
                            <Checkbox checked={selectedReportIds.includes(report.id)}
                                onChange={(evt, data) => {
                                    let selected
                                    if (data.checked) {
                                        selected = [report.id, ...selectedReportIds]
                                    } else {
                                        selected = selectedReportIds.filter(id => id != report.id)
                                    }
                                    onChange(selected)
                                }} />
                        </td>
                        <td>{report[ReportField.YEAR]}</td>
                        <td>{report[ReportField.PERIOD]}</td>
                        <td>{report[ReportField.TYPE]}</td>
                        <td>{report[ReportField.REPORT_TYPE]}</td>
                        <td>{report[ReportField.FULL_CHECKS_COUNT]}</td>
                        <td>{report[ReportField.VISUAL_CHECKS_COUNT]}</td>
                        <td>{serverToLocalDate(report[ReportField.CREATED_ON])}</td>
                        <td>{serverToLocalDate(report[ReportField.UPDATED_ON])}</td>
                    </tr>
                )}
            </tbody>
        </table>
    </>
}


function EditUpdateModal({ updateId, onSaved, onCancel }) {
    const [update, setUpdate] = useState(null)
    let [error, setError] = useState(false)

    useEffect(() => {
        TableUpdatesRepo.get(updateId, update => {
            setUpdate({
                updateId: update.id,
                description: update.description,
                purpose: update.purpose,
                targetReviewers: update.targetReviewers,
                ownerId: update.ownerId,
                companyId: update.companyId,
                minFullChecks: update.minFullChecks,
                minVisualChecks: update.minVisualChecks,
                minCDChecks: update.minCDChecks
            })
        })
    }, [updateId]);


    function assingUpdateChange(change) {
        setUpdate(Object.assign({}, update, change))
        setError({})
    }

    function setOwnerId(newOwnerId) {
        const newTargetReviewers = update.targetReviewers
            .filter(userId => userId !== newOwnerId)

        assingUpdateChange({
            targetReviewers: newTargetReviewers,
            ownerId: newOwnerId
        })
    }

    function setDescription(description) {
        assingUpdateChange({ description })
    }

    function setTargetReviewers(targetReviewers) {
        targetReviewers = targetReviewers
            .filter(userId => userId !== update.ownerId)
        assingUpdateChange({ targetReviewers })
    }

    function save() {
        const error = {};
        if (!valdateDescription(update.description)) {
            error.description = { content: INVALID_DESCRIPTION_MSG }
        }

        if (!validateMinChecks(update.minFullChecks, update.minVisualChecks)) {
            error.minchecks = { content: INVALID_MINCHECKS_MSG }
        }

        if (Object.keys(error).length) {
            setError(error)
        } else {
            TableUpdatesRepo.update(update, onSaved)
        }

    }

    function getMinReviewsInput(type, prop, disabled) {
        return <NumInput
            label={"Minimal " + type + " Checks"}
            error={disabled ? null : error.minchecks}
            value={update[prop]}
            disabled={disabled}
            onChange={newVal => assingUpdateChange({ [prop]: newVal })} />
    }

    let content
    if (update === null) {
        content = <Loader />
    } else {
        const ownerId = update.ownerId
        const notUM = !AuthService.hasGlobalRole(ROLES.UPDATES_MANAGER)
        content = (
            <Modal size="large" open={true}>
                <Modal.Header>Edit Draft</Modal.Header>
                <Modal.Content>
                    <Form>
                        Description
                        <Form.Field
                            error={error.description}
                            control={TextArea}
                            value={update.description} onChange={v => {
                                setDescription(v.target.value)
                            }} />
                        <UserSelect
                            label="Target Reviewers"
                            multiple={true}
                            disabled={[ownerId]}
                            onChange={setTargetReviewers}
                            selected={update.targetReviewers} />
                        <UserSelect
                            label="Owner"
                            disabled={[]}
                            onChange={setOwnerId}
                            selected={ownerId} />
                        {getMinReviewsInput("Visual", "minVisualChecks", notUM)}
                        {getMinReviewsInput("Full", "minFullChecks", notUM)}
                        {getMinReviewsInput("CD", "minCDChecks", true)}

                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button size='mini' positive onClick={save}>
                        Save
                    </Button>
                    <Button size='mini' negative onClick={onCancel}>
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    return content
}

function UpdateChangesModal({ updateId, onClose }) {
    const [updateChangeschanges, setChanges] = useState(null)

    useEffect(() => {
        TableUpdatesRepo.getChanges(updateId, setChanges)
    }, [updateId]);

    return (
        updateChangeschanges === null ? <Loader /> :
            <Modal size="fullscreen" open={true}>
                <Modal.Header>Changes</Modal.Header>
                <Modal.Content style={{ overflow: "scroll" }}>
                    <TableChangesWrapper
                        title="Changes in peer review phase"
                        changeKnowledge={updateChangeschanges.changesInPearReviewPhase} />
                    <TableChangesWrapper
                        title="Changes in QA review phase"
                        changeKnowledge={updateChangeschanges.changesInQaReviewPhase} />
                    <TableChangesWrapper
                        title="Applied Changes"
                        changeKnowledge={updateChangeschanges.appliedChanges} />
                </Modal.Content>
                <Modal.Actions>
                    <Button size='mini' onClick={onClose}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
    )
}

function ReviewChangesModal({ reviewId, onClose }) {
    const [changesRes, setChanges] = useState(null)

    useEffect(() => {
        TableUpdatesRepo.getReviewChanges(reviewId, setChanges)
    }, [reviewId]);

    return (
        changesRes === null ? <Loader /> :
            <Modal size="fullscreen" open={true}>
                <Modal.Header>Changes</Modal.Header>
                <Modal.Content style={{ overflow: "scroll" }}>
                    <TableChangesWrapper
                        title="Review Changes"
                        changeKnowledge={changesRes.changes} />
                </Modal.Content>
                <Modal.Actions>
                    <Button size='mini' onClick={onClose}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
    )
}


function TableChangesWrapper({ title, changeKnowledge }) {
    return (
        <Segment>
            <Header as="h4" >{title}</Header>
            {changeKnowledge ? <TableChanges {...{ changeKnowledge }} /> : <>Not detected</>}
        </Segment>
    )
}

function TableChanges({ changeKnowledge }) {
    const diff = changeKnowledge.changes
    const { addedDescriptors, removedDescriptors } = diff.schemaDiff
    const reportsDiff = diff.reportsDiff
    const { addedReports, removedReports, reports } = reportsDiff

    const nonDiffDescriptors = { year: "Year", period: "Period" }
    const descriptors = Object.assign({}, nonDiffDescriptors, reportsDiff.descriptors)

    const tableRows = []
    if (Object.keys(reportsDiff.descriptors).length > 0) {
        for (const decriptorId of Object.keys(descriptors)) {
            const tableRow = []
            tableRow.push(descriptors[decriptorId])
            tableRow.push(decriptorId.replace("custom__", "").slice(0, 8))
            for (const report of reports) {
                tableRow.push(nonDiffDescriptors[decriptorId] === undefined ? <RecordDiff diff={report.diffs[decriptorId]} /> : report[decriptorId])
            }

            tableRows.push(tableRow)
        }
    }

    const statistic = changeKnowledge.statistic
    return (
        <>
            <Header as="h6" className='thinVeritcalMargin'>Summary</Header>
            <table className='tableUpdateDiffTable statistics'>
                <tbody>
                    <tr>
                        <td>Added Reports</td>
                        <td>{statistic.addedReports} </td>
                    </tr>
                    <tr>
                        <td>Removed Reports</td>
                        <td>{statistic.removedReports} </td>
                    </tr>
                    <tr>
                        <td>Added Core Values</td>
                        <td>{statistic.addedCoreValues} </td>
                    </tr>
                    <tr>
                        <td>Changed Core Values</td>
                        <td>{statistic.changedCoreValues} </td>
                    </tr>
                    <tr>
                        <td>Removed Core Values</td>
                        <td>{statistic.removedCoreValues} </td>
                    </tr>
                    <tr>
                        <td>Added Labels</td>
                        <td>{statistic.addedLabels} </td>
                    </tr>
                    <tr>
                        <td>Changed Labels</td>
                        <td>{statistic.changedLabels} </td>
                    </tr>
                    <tr>
                        <td>Removed Labels</td>
                        <td>{statistic.removedLabels} </td>
                    </tr>
                    <tr>
                        <td>Added cells beacuse of added field to the model</td>
                        <td>{statistic.dataAddedBecauseOfNewDescriptor} </td>
                    </tr>
                    <tr>
                        <td>Removed cells beacuse of removed field from the model</td>
                        <td>{statistic.dataAddedBecauseOfRemovedDescriptor} </td>
                    </tr>
                </tbody>
            </table>
            <Divider />
            <Header as="h6" className='thinVeritcalMargin' >Cell Changes</Header>
            {tableRows.length === 0 ? "Not detected" :
                <table className='tableUpdateDiffTable'>
                    <tbody>
                        {tableRows.map((row, rolIdx) => {
                            return <tr key={rolIdx}>
                                {row.map((cell, callIdx) => <td key={callIdx}>{cell} </td>)}
                            </tr>
                        })}
                    </tbody>
                </table>
            }
            <Divider />

            <Header as="h6" className='thinVeritcalMargin'>Model changes</Header>
            <DescriptorsList
                addedDescriptorsMap={addedDescriptors}
                removedDescriptorsMap={removedDescriptors} />
            <Divider />

            <Header as="h6" className='thinVeritcalMargin' >Added/Removed reports</Header>
            <ReportsSummaryList
                addedReports={addedReports}
                removedReports={removedReports} />

        </>
    )
}

function ReportsSummaryList({ addedReports, removedReports }) {
    return (
        <>
            {addedReports.length === 0 && removedReports.length === 0 ? "Not detected" :
                <>
                    <ul>
                        {addedReports.map((r, idx) => <li className='greenText' key={idx} >{r}</li>)}
                        {removedReports.map((r, idx) => <li className='redText' key={"_" + idx} >{r}</li>)}
                    </ul>
                </>}
        </>
    )
}

function DescriptorsList({ addedDescriptorsMap, removedDescriptorsMap }) {
    const addedDescriptorIds = Object.keys(addedDescriptorsMap)
    const removedDescriptorIds = Object.keys(removedDescriptorsMap)
    return (
        <>
            {addedDescriptorIds.length === 0 && removedDescriptorIds.length === 0 ? "Not detected" :
                <table className='tableUpdateDiffTable statistics'>
                    <tbody>
                        <tr>
                            <td>Field Name</td>
                            <td>Field Id</td>
                        </tr>
                        {addedDescriptorIds.map((id, idx) =>
                            <tr className='greenText' key={idx} >
                                <td>{addedDescriptorsMap[id]}</td>
                                <td>{id.replace("custom__", "").slice(0, 8)}</td>
                            </tr>)}
                        {removedDescriptorIds.map((id, idx) =>
                            <tr className='redText' key={"_" + idx} >
                                <td>{removedDescriptorsMap[id]}</td>
                                <td>{id.replace("custom__", "").slice(0, 8)}</td>
                            </tr>)}
                    </tbody>
                </table>
            }
        </>
    )
}

function RecordDiff({ diff }) {

    const label = diff?.label
    const coreValue = diff?.coreValue
    return (
        diff ?
            <table>
                <tbody>
                    <tr>
                        {label &&
                            <td>
                                <span className='redText'> {label.oldVal}</span>
                                <span className='greenText'> {label.newVal}</span>
                            </td>
                        }
                    </tr>
                    <tr>
                        {coreValue &&
                            <td>
                                <span className='redText'> {coreValue.oldVal}</span>
                                <span className='greenText'> {coreValue.newVal}</span>
                            </td>
                        }
                    </tr>
                </tbody>
            </table> : <></>
    )
}

function UpdatesTab({ companyId, companyName, schemaId, schemaName, reloadSchemas }) {
    const [updates, setUpdates] = React.useState(null)
    const [modal, setModal] = React.useState(null)

    useEffect(() => {
        refreshUpdates(false)
    }, [schemaId])


    function refreshUpdates(refreshSchemas = true) {
        TableUpdates.find({ srcSchema: schemaId }).then(updates => {
            setUpdates(updates)
            if (refreshSchemas) {
                reloadSchemas()
            }
        })
    }

    function createDraft() {
        function saveHandler() {
            refreshUpdates()
            setModal()
        }

        setModal(<CreateUpdateModal summary={companyName + " (" + schemaName + ")"}
            {...{ schemaId, companyId }} onSaved={saveHandler} onCancel={() => setModal()} />)
    }

    function listUpdates() {
        const newUpdateAllowed = !updates.find(u => u.phase !== "APPLIED")
        return <>
            {newUpdateAllowed &&
                <Button floated='right' size='mini' primary onClick={createDraft}>
                    Prepare Update
                </Button>
            }
            <UpdatesTable {...{ updates, onUpdatesChange: refreshUpdates }} />
        </>
    }

    return <div >
        {modal}{updates === null ? <Loader /> : listUpdates()}
    </div>

}

const updateSorting = {
    byCreatedOn: (updates, ascOrder) => sortByDate("createdOn", updates, ascOrder),
    byUpdatedOn: (updates, ascOrder) => sortByDate("updatedOn", updates, ascOrder),
    byActivity: (updates, ascOrder) => sortByActivity(updates, ascOrder)
}

function sortByDate(prop, updates, ascOrder) {
    const multiplier = ascOrder ? 1 : -1
    updates.sort((a, b) => (new Date(b[prop]) - new Date(a[prop])) * multiplier)
}

function sortByActivity(updates, ascOrder) {
    const multiplier = ascOrder ? 1 : -1
    updates.sort((a, b) => (getlatestActivity(b) - getlatestActivity(a)) * multiplier)
}

function getlatestActivity(update) {
    let latest = new Date(update.updatedOn)
    for (const review of update.reviews) {
        if (new Date(review.updatedOn) > latest) {
            latest = new Date(review.updatedOn)
        }
    }

    return latest
}

function UpdatesTable({ updates, onUpdatesChange, detailed = true, includeMistakes = true }) {
    const [sortFuncKey, setSortFuncKey] = useState("byActivity")
    const [ascOrder, setAscOrder] = useState("ASC")
    const [ownerId, setOwnerId] = useState(0)
    const [owners] = useState(() => Object.values(updates.reduce((map, u) => {
        map[u.owner.id] = u.owner;
        return map;
    }, {})))
    updateSorting[sortFuncKey](updates, ascOrder === "ASC")

    return updates.length === 0 ? <p>No updates.</p> :
        <>
            <SelectField label="Sort By: "
                valuesMap={[
                    new KeyValue("byCreatedOn", "Created On"),
                    new KeyValue("byUpdatedOn", "Updaeted On"),
                    new KeyValue("byActivity", "Activity")
                ]}
                value={sortFuncKey}
                onChange={key => setSortFuncKey(key)} />
            <SelectField label="Order: "
                valuesMap={[
                    new KeyValue("ASC", "ASC"),
                    new KeyValue("DESC", "DESC")
                ]}
                value={ascOrder}
                onChange={key => setAscOrder(key)} />
            <SelectField number={true} label="Owner: "
                valuesMap={[new KeyValue(null, "All"), ...owners.map(u => new KeyValue(u.id, u.fullName))]}
                value={ownerId}
                onChange={key => setOwnerId(key)} />

            <Table striped className="revewRequiestTable">
                <Table.Header>
                    <Table.Row >
                        <Table.HeaderCell collapsing></Table.HeaderCell>
                        <Table.HeaderCell collapsing></Table.HeaderCell>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        {detailed ?
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            :
                            <Table.HeaderCell>Summary</Table.HeaderCell>}

                        <Table.HeaderCell>Owner</Table.HeaderCell>
                        <Table.HeaderCell>Phase</Table.HeaderCell>
                        <Table.HeaderCell>Purpose</Table.HeaderCell>
                        <Table.HeaderCell>Reviews</Table.HeaderCell>
                        {includeMistakes && <>
                            <Table.HeaderCell>Mistakes Score</Table.HeaderCell>
                        </>}
                        <Table.HeaderCell>Created On</Table.HeaderCell>
                        <Table.HeaderCell>Updated On</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {updates
                        .filter(u => ownerId === 0 || u.owner.id === ownerId)
                        .map((u, i) =>
                            <Table.Row key={u.id} warning={isReadyForNextPhase(u)}>
                                <Table.Cell collapsing>
                                    {i + 1}
                                </Table.Cell>
                                <Table.Cell collapsing className='noPadding'>
                                    <UpdateOps update={u} {...{ onUpdatesChange }} />
                                </Table.Cell>
                                <Table.Cell>
                                    {u.id}
                                </Table.Cell>
                                {detailed ?
                                    <Table.Cell>
                                        <div dangerouslySetInnerHTML={{ __html: u.description.replace(/(?:\r\n|\r|\n)/g, '</br>') }}></div>
                                    </Table.Cell>
                                    :
                                    <Table.Cell>
                                        <Popup size='tiny' wide
                                            content={u.description} trigger={<Link
                                                to={PathFactory.create(
                                                    [
                                                        RouteC.EDIT_COMPANY_CONFIG_INTERNAL,
                                                        u.companyId, AdministrationTabs.UPDATES, u.srcSchemaId
                                                    ])
                                                }>{u.summary}
                                            </Link>} />
                                    </Table.Cell>
                                }

                                <Table.Cell>
                                    {u.owner.disabled ?
                                        <Label color="red" size="mini">
                                            {u.owner.fullName}
                                        </Label>
                                        : u.owner.fullName}

                                </Table.Cell>
                                <Table.Cell>
                                    {u.phase}
                                </Table.Cell>
                                <Table.Cell>
                                    {u.purpose}
                                </Table.Cell>
                                <Table.Cell className="reviewStatusCell">
                                    <Reviews reviews={u.reviews}
                                        onChange={onUpdatesChange}
                                        includeMistakes={includeMistakes} />
                                </Table.Cell>
                                {includeMistakes && <>
                                    <Table.Cell>
                                        <UpdateMistakesScore update={u} />
                                    </Table.Cell>
                                </>}
                                <Table.Cell>
                                    {serverToLocalDate(u.createdOn)}
                                </Table.Cell>
                                <Table.Cell>
                                    <DateLabel date={u.updatedOn}
                                        actionRequired={u.phase !== UpdatePhase.APPLIED} />
                                </Table.Cell>
                            </Table.Row>
                        )}
                </Table.Body>
            </Table>
        </>
}

function UpdateMistakesScore({ update }) {

    const showMistakes = update.purpose !== UpdatePurpose.MISTAKES_DETECTION && update.purpose !== UpdatePurpose.DEFERRED_REVIEWS_EXECUTION
    const score = showMistakes ? getMistakesScoreOfUpdate(update) : "N/A"

    return score === "" ? "" :
        <Popup wide
            size='tiny'
            content={
                <>
                    Mistake Points from Peers: {Number(update.mistakePointsFromPeer).toFixed(1)}<br />
                    Mistake Points from QAs: {Number(update.mistakePointsFromQa).toFixed(1)}<br />
                    Cells Scope: {update.cellsScope}
                    <Divider />
                    Added Reports: {update.addedReports}<br />
                    Total Reports In Table: {update.totalReports}
                </>
            }
            trigger={<span>{score}</span>}
        />
}

function getMistakesScoreOfUpdate(update) {
    if (update.mistakePointsFromPeer === null || update.mistakePointsFromQa === null) {
        return ""
    } else {
        const cellsScope = update.cellsScope
        const totalPoints = update.mistakePointsFromPeer + update.mistakePointsFromQa
        if (cellsScope === 0) {
            if (totalPoints > 0) {
                Alert.warn("Unexpected sells scope. Please contact administrator")
            }
            return ""
        }
        return (Number(totalPoints / cellsScope) * 100).toFixed(1)
    }
}



const currentTime = new Date().getTime()
function DateLabel({ date, actionRequired }) {
    const time = new Date(date).getTime()
    const passedDays = (currentTime - time) / (1000 * 60 * 60 * 24)
    const formatedDate = serverToLocalDate(date)

    let res
    if (!actionRequired || passedDays < 2) {
        res = formatedDate
    } else {
        res = <Label color={passedDays > 4 ? "red" : "yellow"}
            size="mini">{formatedDate}</Label>
    }

    return res
}

function UpdatesTableSummary({ updates, includeMistakes, onUpdatesChange = () => { } }) {
    return <UpdatesTable {...{ updates, includeMistakes }} detailed={false}
        onUpdatesChange={onUpdatesChange} />
}

function Reviews({ reviews, onChange, includeMistakes }) {
    const [reviewModal, setReviewModal] = React.useState(null)

    function handleReviewDeletion() {
        onChange()
        setReviewModal(null)
    }

    function handleReviewStatusChange() {
        onChange()
    }

    function openRevew(reviewId) {
        setReviewModal(<ReviewModal
            {...{ reviewId, handleReviewDeletion, handleReviewStatusChange }}
            onClose={() => setReviewModal(null)}
        />
        )
    }

    function viewChanges(reviewId) {
        setReviewModal(<ReviewChangesModal
            {...{ reviewId }}
            onClose={() => setReviewModal(null)}
        />
        )
    }

    return <>
        {reviewModal}
        {reviews.map(r =>
            <Segment key={r.id} className='cursorPointer'
                onClick={() => r.phase === "ARCHIVED" ? viewChanges(r.id) : openRevew(r.id)} >

                {r.owner.disabled ?
                    <Label color="red" size="mini">
                        {r.owner.fullName}
                    </Label>
                    : r.owner.fullName}
                <br />
                {r.type}
                {r.notes && <Popup size='tiny' wide
                    content={r.notes} trigger={<span> * </span>} />}
                <br />
                {reportStatusToLablel(r.status)}<br />

                {includeMistakes && <ReviewMistakesScore review={r} />}

                {serverToLocalDate(r.createdOn)} - {serverToLocalDate(r.updatedOn)}
            </Segment>)}
    </>
}

function ReviewMistakesScore({ review }) {

    const score = getMistakesScoreOfReview(review)
    const mistakePoints = score === "" ? "N/A" : Number(review.mistakePoints).toFixed(1)

    return <>
        Mistakes Score:
        <Popup size='tiny' wide
            content={
                <>
                    Mistake Points: {mistakePoints}<br />
                    Cells Scope: {review.cellsScope}
                    <Divider />
                    Reviewed Reports: {review.reportsScope}
                </>
            } trigger={<span> {score === "" ? "N/A" : score}</span>} />
        <br />
    </>

}

function getMistakesScoreOfReview(review) {
    const cellsScope = review.cellsScope
    const mistakePoints = review.mistakePoints
    if (cellsScope === null || mistakePoints === null) {
        return ""
    } else if (cellsScope === 0) {
        if (mistakePoints > 0) {
            Alert.warn("Unexpected sells scope. Please contact administrator")
        }
        return ""
    }
    return Number(mistakePoints / cellsScope * 100).toFixed(2)
}


function ReviewModal({ reviewId, handleReviewDeletion, handleReviewStatusChange, onClose }) {
    return <Modal open={true} size='fullscreen'>
        <Modal.Header>
            Review
            <Icon color='grey' name='close'
                className='cursorPointer floatRight' onClick={onClose} />
        </Modal.Header>
        <Modal.Content>
            <ReviewTab reviewId={reviewId} onDeleted={handleReviewDeletion}
                onReviewStatusChange={handleReviewStatusChange} />
        </Modal.Content>
        <Modal.Actions>
            <Button size='mini' onClick={onClose}>
                Close
            </Button>
        </Modal.Actions>
    </Modal>
}

function UpdateOps({ update, onUpdatesChange, editDraft }) {
    const [modal, setModal] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    function discardUpdate(updateId) {
        setModal(<ConfirmationModal
            msg={deleteConfirmationMsg}
            onConfirm={() => {
                TableUpdatesRepo.delete(updateId, onUpdatesChange)
                setModal()
            }}
            onCancel={() => setModal()} />)
    }

    function applyUpdate(updateId) {
        setLoading(true)
        TableUpdatesRepo.apply(updateId, () => {
            setLoading(false)
            onUpdatesChange()
        })
    }

    function editDraft(update) {
        function saveHandler() {
            onUpdatesChange()
            setModal()
        }

        setModal(<EditUpdateModal updateId={update.id} onSaved={saveHandler}
            onCancel={() => setModal()} />)
    }

    function markAsReadyForPeerReview(updateId) {
        markAsReadyForReview("Are you sure that you want to request a peer review?", () =>
            TableUpdatesRepo.markAsReadyForPeerReview(updateId, onUpdatesChange))
    }


    function markAsReadyForQaReview(updateId) {
        markAsReadyForReview("Are you sure that you want to request a QA review?", () =>
            TableUpdatesRepo.markAsReadyForQaReview(updateId, onUpdatesChange))
    }

    function requestReviewsRevision(updateId) {
        markAsReadyForReview("Are you sure that you want to request a revision of the reviews?", () =>
            TableUpdatesRepo.requestReviewsRevision(updateId, onUpdatesChange))
    }

    function markAsReadyForReview(msg, remoteCall) {
        setModal(<ConfirmationModal
            msg={msg}
            onConfirm={() => {
                remoteCall()
                setModal()
            }}
            onCancel={() => setModal()} />)
    }

    function startReview(updateId, reviewType) {
        setLoading(true)
        TableUpdatesRepo.startReview(
            { updateId, reviewType }, () => {
                setLoading(false)
                onUpdatesChange()
            })
    }

    function viewChanges(update) {
        setModal(<UpdateChangesModal updateId={update.id} onClose={() => setModal()} />)
    }

    function getOp(name, onClick) {
        return {
            key: name,
            size: "mini",
            text: name,
            value: name,
            onClick: onClick
        }
    }

    const ops = []

    const phase = update.phase
    const updateId = update.id

    if (phase !== UpdatePhase.APPLIED) {
        const isCurrentUserUpdateOwner = update.ownerId === AuthService.getUserId()

        if (isCurrentUserUpdateOwner) {
            ops.push(getOp("Edit", () => editDraft(update)))
            if (phase === UpdatePhase.DRAFT) {
                ops.push(getOp("Mark as ready for Peer Review", () => markAsReadyForPeerReview(updateId)))
            } else if (phase === UpdatePhase.READY_FOR_PEER_REVIEW) {
                ops.push(getOp("Mark as ready for QA Review", () => markAsReadyForQaReview(updateId)))
            } else if (phase === UpdatePhase.READY_FOR_QA_REVIEW && isReadyForNextPhase(update)) {
                ops.push(getOp("Apply", () => applyUpdate(updateId)))
            }

            if (update.reviews.find(r => r.status === "APPROVAL_AUTO_REJECTED" || r.status === "REJECTED")) {
                ops.push(getOp("Request Review Revision", () => requestReviewsRevision(updateId)))
            }

            ops.push(getOp("Delete", () => discardUpdate(updateId)))
        } else if (!update.reviews.find(r => r.ownerId === AuthService.getUserId())) {
            for (const type of update.allowedReviews) {
                ops.push(getOp("Start " + type + " Review", () => startReview(updateId, type)))
            }
        }

        if (!isCurrentUserUpdateOwner && AuthService.hasGlobalRole(ROLES.UPDATES_MANAGER)) {
            ops.push(getOp("Edit", () => editDraft(update)))
        }
    } else {
        ops.push(getOp("View Changes", () => viewChanges(update)))
    }

    return <>
        {loading && <Loader />}
        {modal}
        <Dropdown
            className='icon fieldOps'
            size="mini"
            icon="ellipsis vertical"
            options={ops}
            trigger={<></>} />
    </>

}

export { UpdatesTableSummary, UpdatesTab } 
