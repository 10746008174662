import { SUPPORTED_YEARS } from "../../constnats/generalConstants"
import { ReportField, ReportFillingType, ReportType, SrcFieldValue } from "../../constnats/reportConstants"
import ScaleUtil from "../scaleUtil"
import Alert from "../alert"
import { CalculationExpUtil } from "./calculationExp"
import { DescriptorCategoryProps } from "../../pages/admin/company/components/financialTables/schema/descriptors/models/descriptorModel"
import KeyValue from "../../structures/keyValePair"
import CategoriesRepo from "../repository/categoriesRepo"
import Assert from "../asserts"
import { DescriptorProps } from "../../constnats/descriptor"
import DescriptorType from "./descriptorType"

const GLOBAL_PREDEFINED_VALUES = {
    [ReportField.YEAR]: SUPPORTED_YEARS,
    [ReportField.PERIOD]: ["H1", "H2", "FY", "Q1", "Q2", "Q3", "Q4", "9M"],
    [ReportField.TYPE]: ReportFillingType.PERSISTENT_TYPES,
    [ReportField.SRC]: SrcFieldValue.ALL_PERSISTED,
    [ReportField.REPORT_TYPE]: ReportType.ALL,
    [DescriptorProps.INPUT_SCALE]: ScaleUtil.scalePairs
}

const ALLOWED_VALUES = {
    [ReportField.YEAR]: SUPPORTED_YEARS,
    [ReportField.PERIOD]: ["H1", "H2", "FY", "Q1", "Q2", "Q3", "Q4", "9M"],
    [ReportField.TYPE]: ReportFillingType.PERSISTENT_TYPES,
    [ReportField.SRC]: SrcFieldValue.ALL,
    [ReportField.REPORT_TYPE]: ReportType.ALL,
    [DescriptorProps.INPUT_SCALE]: ScaleUtil.scalePairs
}

class Utils {

    /**
     * @param {[]} decriptors As stored in the database. Required
     * @returns not-null flatten descriptors with subFields  preserved
     */
    static flatDescriptors(decriptors) {
        decriptors = JSON.parse(JSON.stringify(decriptors))
        const flatDecriptors = []

        for (const decriptor of decriptors) {
            flatDecriptors.push(decriptor)
            flatDecriptors.push(...Utils.flatDescriptors(decriptor.subFields))
        }

        return flatDecriptors
    }

    /**
     * 
     * @param {[]} decriptors As stored in the database. Required
     * @param {function} func A function to be executed for every descriptor
     * including the sub descriptors. NOTE: The function can change the
     * descriptors but cannot change hierarchy e.g. Remove/Add descriprors to
     * the subFields.
     */
    static forEach(decriptors, func) {
        for (const decriptor of decriptors) {
            func(decriptor)
            Utils.forEach(decriptor[DescriptorProps.SUBFIELDS], func)
        }
    }

    /**
     * @param {[]} decriptors As stored in the database. Required
     * @returns not-null flatten descriptors with subFields  preserved
     */
    static flatDescriptorsMap(decriptors) {
        decriptors = JSON.parse(JSON.stringify(decriptors))
        const flatDecriptors = {}

        for (const decriptor of decriptors) {
            flatDecriptors[decriptor.id] = decriptor
            Object.assign(flatDecriptors, Utils.flatDescriptorsMap(decriptor.subFields))
        }

        return flatDecriptors
    }

    /**
     * @param {string} id Desctiptor id. Required
     * @param {[]} descriptors Schema descriptos as stored in the database. Required 
     * @returns {{}} a referance to the desctiptor as stored in the database or
     *               null if the ID id not found
     */
    static findById(id, descriptors) {
        const find = (id, descriptors) => {
            for (const descriptor of descriptors) {

                let res = null
                if (descriptor.id === id) {
                    res = descriptor
                } else {
                    res = find(id, descriptor.subFields)
                }

                if (res !== null) {
                    return res
                }
            }

            return null
        }

        return find(id, descriptors)
    }

    /**
     * @param {string} id Desctiptor id. Required
     * @param {[]} descriptors Schema descriptos as stored in the database. Required 
     * @returns {{}} a referance to the desctiptor's containing collection or
     *               null if the ID id not found
     */
    static findContainingCollectionOfId(id, descriptors) {
        const find = (id, descriptors) => {
            for (const descriptor of descriptors) {

                let res = null
                if (descriptor.id === id) {
                    res = descriptors
                } else {
                    res = find(id, descriptor.subFields)
                }

                if (res !== null) {
                    return res
                }
            }

            return null
        }

        return find(id, descriptors)
    }


    /**
     * @param {string} descriptorId Descriptor Id to check dependanies for. Required
     * @param {[]} descriptorsToCheck Flat descriptors to search for depenadancies. Required 
     * @returns {string[]} not-null ids of the depenadancies
     */
    static dependancyList(descriptorId, descriptorsToCheck) {
        return new Set(descriptorsToCheck
            .filter(d => Utils._depandancyCheck(descriptorId, d, descriptorsToCheck))
            .map(d => d.id))
    }

    /**
     * @param {{}} descriptor. Required
     * @returns {boolean} True if the descriptor data is scalable.
     */
    static isDataScalabel(descriptor) {
        return !Utils.hasCalculation(descriptor) &&
            DescriptorType.isNumber(descriptor) &&
            descriptor.scalable === true
    }

    /**
     * @param {{}} descriptor. Required
     * @returns {boolean} True if the descriptor correction is scalable.
     */
    static isCorrectionScalabel(descriptor) {
        return Utils.supportsCorrection(descriptor) && descriptor.scalable === true
    }

    /**
     * @param {{}} descriptor. Required
     * @returns True if the ddescriptor has specified calculation.
     */
    static hasCalculation(descriptor) {
        return descriptor.calculation !== CalculationExpUtil.NO_OP_CALC
    }

    /**
     * @param {{}} descriptor. Required
     * @returns {KeyValue[]}
     */
    static predefinedValues(descriptor) {

        /**
         * The code bellow is commented because the server has incorrect
         * predefined values. It must be uncommented once the predefined values
         * on the server are fixed. NOTE: Consider general removal of the
         * predefinedValues from the server.
         */
        // const localPredefinedValues = descriptor.predefinedValues
        // const hasLocalPredefinedValues = localPredefinedValues &&
        //     localPredefinedValues.length > 0

        let res = []
        // if (hasLocalPredefinedValues) {
        //     res = localPredefinedValues.map(KeyValue.createSame)
        // } else {
        const globalPredefinedValues = GLOBAL_PREDEFINED_VALUES[descriptor.id]
        if (globalPredefinedValues !== undefined) {
            res = typeof globalPredefinedValues[0] === 'string' ?
                globalPredefinedValues.map(KeyValue.createSame) :
                globalPredefinedValues.map(o => new KeyValue(String(o.key), o.value))
        }
        // }

        return res
    }

    /**
     * @param {{}} descriptor. Required
     * @returns {KeyValue[]}
     */
    static allowdValues(descriptor) {
        let res = []

        const allowedValues = ALLOWED_VALUES[descriptor.id]
        if (allowedValues !== undefined) {
            res = typeof allowedValues[0] === 'string' ?
                allowedValues.map(KeyValue.createSame) :
                allowedValues.map(o => new KeyValue(String(o.key), o.value))
        }

        return res
    }

    /**
     * @param {{}} descriptor. Required
     * @returns True if the descriptor represents 3D calculated ratio.
     */
    static is3dRatio(descriptor) {
        return DescriptorType.isPercent(descriptor) && Utils.hasCalculation(descriptor)
    }

    /**
    * @param {{}} descriptor. Required
    * @returns True if the ddescriptor has specified secondary calculation.
    */
    static hasSecondaryCalculation(descriptor) {
        return descriptor.secondaryCalculation !== CalculationExpUtil.NO_OP_CALC
    }

    /**
     * @param {{}} descriptor. Required
     * @returns True if the ddescriptor suppoers correction values in its metadata.
     */
    static supportsCorrection(descriptor) {
        return Utils.hasCalculation(descriptor)
    }



    /**
     * @param {[]} schemas As stored in the database. Required
     * @param {func} onSuccess excecuted when the descriptors are updated. Required
     * NOTE: The function makes an API call to get all the categories from the server and 
     * then update the descriptor.category. The property is not updated if the cathegory is not found.
     */
    //TODO: must be refactored, shouldn`t have any api calls in utils
    static addFullCategoriesToSchemas(schemas, onSuccess) {
        CategoriesRepo.getAll((categories) => {
            schemas.forEach(s => Utils._addFullCategories(s.descriptors, categories))
            onSuccess()
        })
    }


    /**
     * @param {{}} descriptors As stored in the database. Required
     * @param {func} onSuccess excecuted after the descriptors are updated. Required
     * NOTE: The function makes an API call to get all the categories from the server and 
     * then update the descriptor.category. The property is not updated if the cathegory is not found.
     */
    //TODO: must be refactored, shouldn`t have any api calls in utils
    static addFullCategories(descriptors, onSuccess) {
        CategoriesRepo.getAll((categories) => {                     
            Utils._addFullCategories(descriptors, categories)
            onSuccess()
        })
    }

    /**
     * @param {[]} descriptors Non flat descriptors. Required
     * @param {[]} categories Categories to add from. Required
     */
    static _addFullCategories(descriptors, categories) {
        for (const descriptor of descriptors) {
            const descriptorCategory = descriptor[DescriptorProps.CATEGORY]
            if (descriptorCategory) {
                const categoryId = descriptorCategory[DescriptorCategoryProps.CATEGORY_ID]
                const category = categories.find(c => c.id == categoryId);
                if (category) {
                    descriptor[DescriptorProps.CATEGORY] = { ...descriptorCategory, ...category }
                } else {
                    Assert.fail("There is no such category " + JSON.stringify(descriptor))
                }
            }
            Utils._addFullCategories(descriptor[DescriptorProps.SUBFIELDS], categories)
        }
    }

    static _depandancyCheck(descriptorId, descriptor, allDescriptors, passedThrough = []) {
        //TODO TMP
        if (passedThrough.length > allDescriptors.length * 5) {
            Alert.error("Circular dependancy detected in some of the model calculations. " +
                "The exact field that caused the dependancy cannot be found aoutmatically. Please fix it and save the model.")
            return false
        }

        passedThrough.push(descriptor.id)
        let hasDependancy = descriptor.id === descriptorId

        if (!hasDependancy && Utils.hasCalculation(descriptor)) {
            hasDependancy = Utils._calcDepandancyCheck(descriptorId, descriptor.calculation, allDescriptors, passedThrough)
        }

        if (!hasDependancy && Utils.hasSecondaryCalculation(descriptor)) {
            hasDependancy = Utils._calcDepandancyCheck(descriptorId, descriptor.secondaryCalculation, allDescriptors, passedThrough)
        }

        return hasDependancy
    }

    static _calcDepandancyCheck(descriptorId, calculation, allDescriptors, passedThrough) {
        const calcIncludedDescriptorIds = CalculationExpUtil.getIncludedIds(calculation)
        if (calcIncludedDescriptorIds.includes(descriptorId)) {
            return true
        }
        const calcIncludedDescriptors = allDescriptors.filter(d => calcIncludedDescriptorIds.includes(d.id))
        return calcIncludedDescriptors.some(d => Utils._depandancyCheck(descriptorId, d, allDescriptors, passedThrough))
    }

    static _clone(obj) {
        return JSON.parse(JSON.stringify(obj))
    }
}


export default Utils