import Alert from "../utils/alert"
import AuthService from "./auth"
import AuthMonitor from "./authMonitor"
import { DEFAULT_FILTER_AND_CFG, DEFAULT_TABLE_CFG } from "../components/financialTables/configuration/constants"
import UserSettingsRepo from "../utils/repository/userSettingsRepo"


class UserPreferences {

    static FILTER_CFG_PROP = "filterCfg"
    static TABLE_CFG_PROP = "tableConfig"

    static CURRENT_SETTINGS_VERSION_PROP = "settingsV"
    static CURRENT_SETTINGS_VERSION = "11"

    static DEFAULTS = {
        [UserPreferences.FILTER_CFG_PROP]: DEFAULT_FILTER_AND_CFG,
        [UserPreferences.TABLE_CFG_PROP]: DEFAULT_TABLE_CFG
    }

    static userSettings = {}

    /**
     * @param {int|string|null} userId Optional 
     */
    static async setUserId(userId) {
        if (userId === null) {
            userId = "_anonymous"
        }

        UserPreferences.userId = userId
        const settings = await UserSettingsRepo.get(userId)
        if (settings[UserPreferences.CURRENT_SETTINGS_VERSION] === UserPreferences.CURRENT_SETTINGS_VERSION) {
            UserPreferences.userSettings = settings
        } else {
            for (const prop of Object.keys(UserPreferences.DEFAULTS)) {
                UserPreferences.save(prop, UserPreferences.DEFAULTS[prop])
            }

            UserPreferences.save(UserPreferences.CURRENT_SETTINGS_VERSION,
                UserPreferences.CURRENT_SETTINGS_VERSION)
        }

    }
    /**
     * @param {int|string} configProp Required 
     */
    static get(configProp) {
        const config = UserPreferences.userSettings[configProp]
        return config === undefined ? UserPreferences.DEFAULTS[configProp] : config
    }

    /**
     * @param {int|string} configProp Required 
     * @param {{}} settings Required
     */
    static save(configProp, settings) {
        UserPreferences.userSettings[configProp] = settings
        UserSettingsRepo.save(UserPreferences.userId, UserPreferences.userSettings)
    }
}

UserPreferences.setUserId(AuthService.getUserId()).catch(() => {
    Alert.error("Failed to load user settings. Please refresh your page.")
})

AuthMonitor.registerAuthChangeListener(() => {
    UserPreferences.setUserId(AuthService.getUserId()).catch(() => {
        Alert.error("Failed to load user settings. Please refresh your page.")
    })
})

export default UserPreferences