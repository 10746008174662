import React from 'react';
import Loader from '../../../../components/loader';
import { ReportsUtils } from '../../../../utils/reportsUtils';
import Table from './table';
import { ReportField, ReportMetadataField, SrcFieldValue } from '../../../../constnats/reportConstants';
import Utils from '../../../../utils/descriptor/descriptorUtils';
import TableAndP2pConfig from '../../../../components/financialTables/configuration/tableAndP2pConfig';
import FilterAndSortCfg from '../../../../components/financialTables/configuration/filterAndSortConfig';
import { Form } from 'semantic-ui-react';
import { applyFilterCfg } from '../../../../components/financialTables/configuration/applyUtill';
import UserPreferences from '../../../../service/userPreferences';
import { getResourceFilter } from '../../../../utils/reportResourceUtil';
import { ConfigProps, PropValues } from '../../../../components/financialTables/configuration/constants';
import ReportUtils from '../../../../utils/reportUtils';
import { CreateReportModal, UpdateReportModal } from "../clientReportsMnmgt"
import Alert from '../../../../utils/alert';
import GlobalReportsRepo from '../../../../utils/repository/globalRepoertsRepo';
import AuthService from '../../../../service/auth';
import TableActionButton from '../../../../components/buttons/tableActionButton';
import AdvancedSettingsToggle from "../../../../components/buttons/advancedSettingsToggle/advancedSettingsToggle"
import { DescriptorProps } from '../../../../constnats/descriptor';
import { BranchTypes } from '../../../../constnats/schemaConstants';

/**
 * Note: Difference between tableWrapper merge function (client view) and reports merge function(filler view)
 * In the client view there are additional types of reports(MANAGED Reports)
 * which should be accounted for, whereas these types of reports are not present in the filler view.
 * Because of this difference there is a significant difference between the logic of the two
 */
/**
 * Function for report merge during horizontal calculations
 * @param {*} a Required 
 * @param {*} b Required
 */
function reportsMergeFunction(a, b) {
    if (a[ReportField.MANAGED] === false && b[ReportField.MANAGED] === true) {
        //b is managed report and a is client report
        ReportUtils.setMetadataProp(a, ReportMetadataField.OBSOLETE, true)

        return b
    } else if (a[ReportField.MANAGED] === true && b[ReportField.MANAGED] === false) {
        //a is managed report and b is client report
        ReportUtils.setMetadataProp(b, ReportMetadataField.OBSOLETE, true)
        return a
    } else {
        ReportUtils.setMetadataProp(a, ReportMetadataField.DUPLICATE, true)
        ReportUtils.setMetadataProp(b, ReportMetadataField.DUPLICATE, true)

        Alert.warn("There are more than one reports for "
            + a.year + ", " + a.period + ", " + a.type + ", " +
            a.reportType + ". This can lead to a incorrect calculations in the calculated periods."
            + " The duplicated periods are marked with red. Please delete the duplicates.")
        return b
    }
}

function AddReportButton({ branchType, isAuthenticated, openCreateReportModal }) {
    return (
        <>
            {
                branchType === "MAIN" && isAuthenticated &&
                <TableActionButton onClick={openCreateReportModal}>
                    Add Report
                </TableActionButton>
            }
        </>
    )
}

function CurrencyInfo ({currency}) {
    return <span>Currency: <b>{currency}</b></span>
}

class ReportsTable extends React.Component {

    constructor(props) {
        super(props)
        this.descriptors = this.props.schema.descriptors
        this.dynamicFlatDescriptors = Utils.flatDescriptors(this.descriptors)
            .filter(d => d[DescriptorProps.DYNAMIC] === true)
        this.reportsUtil = new ReportsUtils(this.dynamicFlatDescriptors)
        this.chartsRef = React.createRef();
        this.state = {
            reports: null,
            modal: null,
            filterCfg: UserPreferences.get(UserPreferences.FILTER_CFG_PROP),
            tableConfig: UserPreferences.get(UserPreferences.TABLE_CFG_PROP)
        }
    }

    componentDidMount() {
        this.reloadReports()
    }

    reloadReports() {
        GlobalReportsRepo.listBySchema(this.props.schema.id,
            reports => this.setReports(reports))
    }

    setReports(nonCalcedReports) {
        const enableAsserts = this.props.schema.branchType === BranchTypes.MAIN;
        const calculatedReports = this.reportsUtil.getCalculatedReports(nonCalcedReports,
            reportsMergeFunction, enableAsserts)

        nonCalcedReports.forEach(report => {
            const fs = ReportUtils.getFieldsMetadata(report)
            let metadataToAdd = {}
            if (report[ReportField.MANAGED] === true) {
                metadataToAdd = { resourceFilterProvider: () => getResourceFilter(report, this.props.companyId) }
            } else {
                metadataToAdd = { editFunc: () => this.openEditReportModal(report.id) }
            }

            fs[ReportField.PERIOD] = Object.assign(metadataToAdd, fs[[ReportField.PERIOD]])
        });
        this.setState({
            reports: nonCalcedReports.concat(calculatedReports)
        })
    }

    onSettingsChange(prop, cfg) {
        this.setState({ [prop]: cfg });
    }

    openEditReportModal(reportId) {
        this.openModal(<UpdateReportModal
            {...{ reportId }}
            schema={this.props.schema}
            onCancel={() => this.closeModal()}
            onUpdated={() => {
                this.reloadReports()
                this.closeModal()
            }} />)
    }

    openCreateReportModal = () => {
        const knownReports = this.state.reports.filter(r => r[ReportField.SRC] !== SrcFieldValue.CALCULATED)
        this.openModal(<CreateReportModal
            knownReports={knownReports}
            schema={this.props.schema}
            onCancel={() => this.closeModal()}
            onCreated={() => {
                this.reloadReports()
                this.closeModal()
            }} />)
    }

    openModal(modal) {
        this.setState({ modal })
    }

    closeModal() {
        this.setState({ modal: null })
    }

    toggleAdvancedSettings = () => {
        this.setState({ filterCfg: { ...this.state.filterCfg, [ConfigProps.SHOW_ADVANCED_SETTINGS]: !this.state.filterCfg[ConfigProps.SHOW_ADVANCED_SETTINGS] } })
        /**
         * Currently there are some issues with the styles which lead to the bottom scrollbar
         * disappearing when the advances settings are opened (this problem does not exist in filler view)
         * by scrolling one pixel it forces the scrollbar to reappear fixing the problem
         */
        window.scrollBy(0, 1)
    }

    render() {
        const tableConfig = this.state.tableConfig
        const filterCfg = this.state.filterCfg
        return this.state.reports === null ? <Loader /> :
            <>
                <>{this.state.modal}</>
                <ConfigForm filterCfg={filterCfg}
                    tableConfig={tableConfig}
                    onChange={(prop, cfg) => this.onSettingsChange(prop, cfg)} 
                    chartsRef={this.chartsRef}/>
                <Table schemaName={this.props.schema.name}
                    reports={ReportsUtils.sort(applyFilterCfg(this.state.reports,
                        filterCfg,
                        tableConfig, this.dynamicFlatDescriptors),
                        filterCfg[ConfigProps.REPORTS_ORDER] === PropValues.DESC)}
                    descriptors={this.descriptors}
                    dynamicFlatDescriptors={this.dynamicFlatDescriptors}
                    {...tableConfig}
                    chartsRef={this.chartsRef}
                    prefferedScale={filterCfg[ConfigProps.PREFFERED_SCALE]}
                    currencyInfo={<CurrencyInfo currency={this.props.currency}/>}
                    advancedSettingsBtn={<AdvancedSettingsToggle showAdvancedSettings={filterCfg[ConfigProps.SHOW_ADVANCED_SETTINGS]} toggleAdvancedSettings={this.toggleAdvancedSettings} />}
                    addReportBtn={<AddReportButton branchType={this.props.schema.branchType} isAuthenticated={AuthService.isAuthenticated()} openCreateReportModal={this.openCreateReportModal} />}
                    showPeriodGrowth={filterCfg[ConfigProps.SHOW_PERIOD_GROWTH_PERCENTAGES]}
                    showYearlyGrowth={filterCfg[ConfigProps.SHOW_YEARLY_GROWTH_PERCENTAGES]}
                />
            </>
    }
}

function ConfigForm({ filterCfg, tableConfig, onChange, chartsRef }) {

    const onChangeInternal = settings => {
        const prop = Object.keys(settings)[0]
        UserPreferences.save(prop, settings[prop])
        onChange(prop, settings[prop])
    }

    return <Form size="mini">
        <FilterAndSortCfg config={filterCfg}
            onChange={filterCfg => onChangeInternal({ filterCfg })}
            granularPeriodsFiltering={tableConfig[ConfigProps.GRANULAR_PERIODS_FILTERING]} />
        {filterCfg[ConfigProps.SHOW_ADVANCED_SETTINGS] && <TableAndP2pConfig chartsRef={chartsRef} tableConfig={tableConfig}
            onTableConfigChange={tableConfig => onChangeInternal({ tableConfig })}
        />}
    </Form>
}

export default ReportsTable