class DescriptorProps {
    static ID = "id"
    static TYPE = "type"
    static LABEL = "label"
    static INPUT_SCALE = "inputScale"
    static VIEW_SCALE = "viewScale"
    static SCALABLE = "scalable"
    static DATA_CALC_TYPE = "dataCalcType"
    static DYNAMIC = "dynamicField"
    static SUBFIELDS = "subFields"
    static NOTE = "note"
    static IMPORTANCE = "importance"
    static STYLE = "style"
    static CALCULATION = "calculation"
    static SECONDARY_CALCULATION = "secondaryCalculation"
    static CATEGORY = "category"
}

class StaticDescriptorsIds {
    static SOURCE = 'src'
}

export { DescriptorProps, StaticDescriptorsIds }